#clinic-details-card {
  img {
    height: 12rem;
  }

  @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
    img {
      height: 15rem;
    }
  }
}
