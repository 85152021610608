#professional-details {
  #photo {
    max-width: 8rem;
  }

  @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
    #photo {
      max-width: 10rem;
    }
  }

  @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
    #photo {
      max-width: 15rem;
    }
  }

  @media screen and (min-width: map-get($grid-breakpoints, 'xl')) {
    #photo {
      max-width: 20rem;
    }
  }
}
