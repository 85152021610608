// Here you can add other styles
.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.f-bold {
  font-weight: bold;
}

.c-border-radius {
  border-radius: 1rem !important;
}

.border-radius-bottom-none {
  border-style: none;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rdt-content {
  position: relative;

  // .rdt-loading {
  //   width: 100%;
  //   height: calc(100% - 4.1rem);

  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  //   align-items: center;

  //   font-size: 1.3rem;

  //   .spinner-border {
  //     width: 5rem;
  //     height: 5rem;
  //   }
  // }

  .rdt-footer {
    display: flex;
    justify-content: space-between;
  }
}

.w-15 {
  width: 15% !important;
}

.bg-alert-danger {
  color: #813838;
  background-color: #fee2e1;
  border-color: #fdd6d6;
}

.cursor-pointer {
  cursor: pointer !important;
}

.card-check-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.card-check-item {
  .form-check-input {
    cursor: pointer;
  }

  .card {
    cursor: pointer;
    transition: all 0.3s ease-in-out, background 0s, color 0s, border-color 0s;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 4px 25px 0 rgba(34, 41, 47, 0.25);
    }
  }

  .card-check-list {
    padding-left: 2rem;
    margin-top: 0.5rem;
  }
}

.card-check-item.disabled {
  .form-check-input {
    cursor: initial;
  }

  .card {
    cursor: initial;
    transition: none;
    opacity: 0.9;

    &:hover {
      transform: none;
      box-shadow: none;
    }
  }
}
